<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          status-icon
          label-position="right"
          label-width="96px"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <el-form-item :label="$t('searchModule.region')" prop="areaId">
            <a-cascader ref="cascader" @change="parkClear"></a-cascader>
          </el-form-item>
          <!-- <el-form-item :label="$t('searchModule.Belonging_operator')">
            <a-operation-select ref="operationSelect" @change="parkClear"></a-operation-select>
          </el-form-item> -->
          <!-- <el-form-item :label="$t('searchModule.Type_of_parking_lot')">
            <a-park-type-select ref="parkTypeSelect" @change="parkClear"></a-park-type-select>
          </el-form-item> -->
          <!-- <el-form-item :label="$t('searchModule.region')">
            <el-select v-model.trim="formInline.areaId"
                      @change='areaChange'
                      filterable
                      size="15"
                      placeholder="请选择">
              <el-option label="全部"
                        value=""></el-option>
              <el-option :label="area.areaName"
                        :value="area.areaId"
                        :key="area.areaId"
                        v-for="area in areaList"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.region')">
            <el-select v-model.trim="formInline.streetId"
                      @change="streetChange"
                      filterable
                      size="15"
                      placeholder="请选择">
              <el-option label="全部"
                        value=""></el-option>
              <el-option :label="area.areaName"
                        :value="area.areaId"
                        :key="area.areaId"
                        v-for="area in areaList2"></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item :label="$t('searchModule.Road_or_Parking_Lot')">
            <a-park-select
              ref="parkSelect"
              :instance="this"
              parkTypeRefName="parkTypeSelect"
              operationRefName="operationSelect"
              areaRefName="cascader"
            ></a-park-select>
          </el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="
              pageNum = 1;
              searchData();
            "
            :loading="loading"
            >{{ $t('button.search') }}</el-button
          >
          <el-button type="primary" icon="el-icon-refresh" @click="resetForm()" :loading="loading"
            >{{ $t('button.reset') }}</el-button
          >
        </el-form>
      </div>
      <div class="circular">
        <el-row :gutter="20" class="list">
          <el-col :span="12">
            <div class="grid-content bg-purple paddingT10 paddingLR20 graphShadow">
              <graphTitle :title="'泊位占用率'">
                <el-tooltip
                  placement="top"
                  content="展示当前的实时泊位利用率泊位利用率=占用泊位数/总泊位数*100%"
                >
                  <i class="el-icon-question my-icon" />
                </el-tooltip>
              </graphTitle>
              <CircularChartPark :type="type1" :berthOccupy="berthOccupy"></CircularChartPark>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple paddingT10 paddingLR20 graphShadow">
              <graphTitle :title="'在场会员车占比'">
                <el-tooltip
                  placement="top"
                  content="展示当前的在场会员车占比在场会员车占比=场内会员车辆数/场内车辆总数*100%"
                >
                  <i class="el-icon-question my-icon" />
                </el-tooltip>
              </graphTitle>
              <CircularChart :type="type2" :memberCarNumber="memberCarNumber"></CircularChart>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="line">
        <LineChart
          class="graphShadow paddingT10 paddingLR20 marginTB20 paddingB10"
          :carHourStatics="carHourStatics"
        ></LineChart>
      </div>
      <div class="area graphShadow paddingT10 paddingLR20 marginTB20">
        <graphTitle :title="'泊位占用趋势'"> </graphTitle>
        <AreaChart :carHourStatics="carHourStatics"></AreaChart>
      </div>
    </div>
  </div>
</template>
<script>
import LineChart from "./lineChart3";
import CircularChart from "../../../../components/circularChart/circularChart7";
import CircularChartPark from "../../../../components/circularChart/circularChart1";
import AreaChart from "../../../../components/areaChart/areaChart";
import myComponent from "@/components/autocomplete/myautoComponent";
import graphTitle from "@/components/graphTitle";
export default {
  name: "parkingLivedata",
  data() {
    return {
      berthOccupy: {},
      memberCarNumber: {},
      carHourStatics: [],
      parkList: [],
      loading: false,
      type1: "c3",
      type2: "c4",
      formInline: {
        parkId: "",
        areaId: "",
        streetId: "",
      },
      areaList: [],
      areaList2: [],
      areaId: "",
    };
  },
  methods: {
    parkClear() {
      this.$refs.parkSelect.clear();
    },
    // 改变城市
    areaChange(item) {
      if (!item) {
        this.formInline.streetId = "";
        return;
      }
      this.resetCarPark();
      this.areaList2 = [];
      this.getChildrenArea(item);
    },
    // 改变区域
    streetChange(item) {
      this.resetCarPark();
    },
    // 清空车场组件信息
    resetCarPark() {
      this.formInline.parkId = "";
      this.formInline.parkName = "";
      // this.$refs.parkInput.setShowVal("");
    },
    // 清空
    resetForm() {
      this.formInline.areaId = "";
      this.formInline.streetId = "";
      this.resetCarPark();
      this.$refs.cascader.clear();
      // this.$refs.operationSelect.clear();
      // this.$refs.datePicker.clear();
      // this.$refs.parkTypeSelect.clear();
      this.parkClear();
    },
    // 初始化用户区域下拉列表（区域和街道）
    getArea() {
      this.$axios.get("/acb/2.0/systems/loginUser/initAreaList").then((res) => {
        if (res.value.depth == 3) {
          this.areaList = res.value.areaList;
          this.formInline.areaId = "";
        } else if (res.value.depth == 4) {
          this.areaList2 = res.value.areaList;
          this.formInline.areaId = "";
        }
      });
    },
    getChildrenArea(item) {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getChildrenArea", {
          data: {
            areaId: item,
          },
        })
        .then((res) => {
          this.areaList2 = res.value;
          this.formInline.streetId = "";
        });
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    searchData() {
      // if (this.formInline.parkId == '') {
      //   this.$alert('请输入并选择停车场', '提示')
      //   this.$refs.parkInput.setShowVal('')
      //   return false
      // }
      this.loading = true;
      // this.$refs.parkInput.setShowVal(this.formInline.parkName);
      if (this.formInline.streetId) {
        this.areaId = this.formInline.streetId;
      } else {
        this.areaId = this.formInline.areaId;
      }
      // this.getBerthOccupy();
      // this.getMemberCarNumber();
      // this.getMemberCarHourStatics();
      this.getParkRealTime();
    },
    // 获取停车实况数据
    getParkRealTime() {
      let streetId = this.$refs.cascader ? this.$refs.cascader.getStreetId() : "";
      let areaId = this.$refs.cascader ? this.$refs.cascader.getAreaId() : "";
      let operationId = this.$refs.operationSelect
        ? this.$refs.operationSelect.getOperationId()
        : "";
      let parkType = this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "";
      let parkId = this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "";
      this.$axios
        .get(
          "/acb/2.0/aceBerth/parkRealTime?parkId=" +
            parkId +
            "&areaId=" +
            areaId +
            "&streetId=" +
            streetId +
            "&operationId=" +
            operationId +
            "&parkType=" +
            parkType
        )
        .then((res) => {
          this.berthOccupy = res.value.berthOccupyRatio ?? {};
          this.memberCarNumber = res.value.memberCarRatio ?? {};
          if (res.value.hourStatics) {
            res.value.hourStatics.forEach((item) => {
              item.hour = item.hour;
            });
          }
          this.carHourStatics = res.value.hourStatics ?? {};
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 泊位占用情况
    // getBerthOccupy() {
    //   let streetId = this.$refs.cascader ? this.$refs.cascader.getStreetId() : "";
    //   let areaId = this.$refs.cascader ? this.$refs.cascader.getAreaId() : "";
    //   let operationId = this.$refs.operationSelect
    //     ? this.$refs.operationSelect.getOperationId()
    //     : "";
    //   let parkType = this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "";
    //   let parkId = this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "";
    //   this.$axios
    //     .get(
    //       "/acb/2.0/parkRealtime/berthOccupy?parkId=" +
    //         parkId +
    //         "&areaId=" +
    //         areaId +
    //         "&streetId=" +
    //         streetId +
    //         "&operationId=" +
    //         operationId +
    //         "&parkType=" +
    //         parkType
    //     )
    //     .then((res) => {
    //       this.berthOccupy = res.value;
    //     })
    //     .finally(() => {
    //       this.loading = false;
    //     });
    // },
    // // 在场车辆数（在场会员车占比）
    // getMemberCarNumber() {
    //   let streetId = this.$refs.cascader ? this.$refs.cascader.getStreetId() : "";
    //   let areaId = this.$refs.cascader ? this.$refs.cascader.getAreaId() : "";
    //   let operationId = this.$refs.operationSelect
    //     ? this.$refs.operationSelect.getOperationId()
    //     : "";
    //   let parkType = this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "";
    //   let parkId = this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "";
    //   this.$axios
    //     .get(
    //       "/acb/2.0/parkRealtime/memberCarNumber?parkId=" +
    //         parkId +
    //         "&areaId=" +
    //         areaId +
    //         "&streetId=" +
    //         streetId +
    //         "&operationId=" +
    //         operationId +
    //         "&parkType=" +
    //         parkType
    //     )
    //     .then((res) => {
    //       this.memberCarNumber = res.value;
    //     });
    // },
    // // 停车场各小时统计数据：进出场数、泊位数
    // getMemberCarHourStatics() {
    //   let streetId = this.$refs.cascader ? this.$refs.cascader.getStreetId() : "";
    //   let areaId = this.$refs.cascader ? this.$refs.cascader.getAreaId() : "";
    //   let operationId = this.$refs.operationSelect
    //     ? this.$refs.operationSelect.getOperationId()
    //     : "";
    //   let parkType = this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "";
    //   let parkId = this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "";
    //   this.$axios
    //     .get(
    //       "/acb/2.0/parkRealtime/hourStatics?parkId=" +
    //         parkId +
    //         "&areaId=" +
    //         areaId +
    //         "&streetId=" +
    //         streetId +
    //         "&operationId=" +
    //         operationId +
    //         "&parkType=" +
    //         parkType
    //     )
    //     .then((res) => {
    //       res.value.splice(0, 1);
    //       res.value.forEach((item) => {
    //         item.hour = item.hour - 1;
    //       });
    //       console.log(res.value);
    //       this.carHourStatics = res.value;
    //     });
    // },
    /* 停车场 */
    parkData() {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getParkName", {
          data: {
            page: 0,
            pageSize: 0,
          },
        })
        .then((res) => {
          this.parkList = res.value.list;
        });
    },
  },
  components: {
    LineChart,
    CircularChart,
    CircularChartPark,
    AreaChart,
    graphTitle,
  },
  beforeDestroy() {
    //   sessionStorage.searchData = JSON.stringify(this.formInline);
    //   sessionStorage.searchFlag = 1;
  },
  created() {
    this.parkData();
    this.getArea();
    // this.searchData();
    if (sessionStorage.searchFlag == 1) {
      // this.formInline = JSON.parse(sessionStorage.searchData);
    }
  },
  computed: {},
  mounted() {
    this.searchData();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.content {
  overflow: hidden;

  .list {
    margin: 20px 0;

    .el-col {
      h2 {
        font-size: 14px;
        color: #8687a5;
        text-align: center;
        padding-bottom: 20px;
      }
    }
  }
}
</style>
